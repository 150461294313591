class Util {
  isFirefox() {
    var agent = navigator.userAgent.toLowerCase();
    if (agent.indexOf("firefox") > 0) {
      return true;
    } else {
      return false;
    }
  }
  isSafari() {
    let agent = navigator.userAgent.toLowerCase();
    if (agent.indexOf("safari") > 0 && agent.indexOf("chrome") == -1) {
      return true;
    } else {
      return false;
    }
  }
  isHuaWei() {
    let agent = navigator.userAgent;
    return agent;
  }
  isIos() {
    let agent = navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if (agent) {
      return navigator.userAgent;
    } else {
      return false;
    }
  }
  isMac() {
    let agent = navigator.userAgent.indexOf("Mac OS");
    if (agent) {
      return agent;
    } else {
      return false;
    }
  }
  browser() {
    var u = navigator.userAgent,
      app = navigator.appVersion;
    console.log(app);
    return {
      trident: u.indexOf("Trident") > -1, //IE内核
      presto: u.indexOf("Presto") > -1, //opera内核
      webKit: u.indexOf("AppleWebKit") > -1, //苹果、谷歌内核
      gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1, //火狐内核
      mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
      ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
      android: u.indexOf("Android") > -1 || u.indexOf("Adr") > -1, //android终端
      iPhone: u.indexOf("iPhone") > -1, //是否为iPhone或者QQHD浏览器
      iPad: u.indexOf("iPad") > -1, //是否iPad
      webApp: u.indexOf("Safari") == -1, //是否web应该程序，没有头部与底部
      weixin: u.indexOf("MicroMessenger") > -1, //是否微信 （2015-01-22新增）
      qq: u.match(/\sQQ/i) == " qq", //是否QQ
    };
  }
  getQueryVariable(variable) {
    let idx = window.location.href.indexOf("?"); //获取当前路径
    var query = window.location.href.substring(idx + 1); //截取?后面的字符串
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  }
}

export default new Util();
