export default {
  getScroll(data) {
    data.isShow = false;
    let imgoneTop =
      parseInt(document.querySelector(data.topid).getBoundingClientRect().top) -
      50;
    if (imgoneTop <= 0) {
      data.showMenu = true;
      data.isShowHeader = false;
    } else {
      data.showMenu = false;
      data.isShowHeader = true;
    }
    document.querySelector(data.target).style.height = "0px";
    return data;
  },
  sizeadapt(size) {
    console.log(8, size);
    let designSize = size; // 设计图尺寸
    // 浏览器宽度
    let rem = (document.documentElement.clientWidth * 100) / designSize;
    document.documentElement.style.fontSize = rem + "px";
    return rem;
  },
};
