<template>
  <div>
    <div :class="topNavData.type === 'C2' ? 'contentC2' : 'contentF2'">
      <img :src="topNavData.img1" />
      <div
        :style="topNavData.isShow ? 'background:#f8f8f8' : ''"
        class="top-1"
        @click="show()"
      >
        <div>
          <span v-html="$t(topNavData.title)"></span>
          <img
            v-if="topNavData.isShow"
            style="transform: scale(0.7)"
            src="@/assets/image/accessorires2/arrow.png"
            alt=""
          />
          <img
            v-if="!topNavData.isShow"
            style="transform: scale(0.7)"
            src="@/assets/image/accessorires2/arrow2.png"
            alt=""
          />
        </div>
      </div>
      <span
        v-if="topNavData.type !== 'P1' && !multiLanguage"
        class="title1 color3A fontBold"
        v-html="$t(topNavData.title)"
      ></span
      ><br />
      <span
        v-if="topNavData.type === 'C2' && !multiLanguage"
        v-html="$t(topNavData.text)"
        class="title2 color3A"
      ></span>
      <div
        v-else-if="multiLanguage"
        style="
          display: flex;
          flex-direction: column;
          z-index: 1;
          top: 80px;
          text-align: center;
          position: absolute;
        "
      >
        <span
          style="font-size: 18px"
          class="color3A fontBold"
          v-html="$t(topNavData.title2)"
        ></span>
        <span
          style="margin-top: 15px; font-size: 12px"
          v-html="$t(topNavData.text)"
          class="color3A"
        ></span>
      </div>
      <span v-else class="title2 color3A" v-html="$t(topNavData.text)"></span>
      <!--<button>购买</button>-->
      <div v-show="topNavData.isShow">
        <div class="top-2">
          <a @click="Click(0)" style="width: 100%" class="item1">
            <span :style="{ color: topNavData.num == 0 ? '#333' : '#ccc' }">{{
              $t("C2.v13")
            }}</span>
            <div class="line"></div>
          </a>
          <a @click="Click(1)" style="width: 100%" class="item1">
            <span :style="{ color: topNavData.num != 0 ? '#333' : '#ccc' }">{{
              $t("details.v5")
            }}</span>
            <div class="line"></div>
          </a>
          <a @click="Click(9)" v-if="pdfMenu" style="width: 100%" class="item1">
            <span :style="{ color: topNavData.num != 0 ? '#ccc' : '#ccc' }">{{
                $t("C2.v14a")
              }}</span>
            <div class="line"></div>
          </a>
          <!--<div class="item1">
            <span>支持</span>
          </div>-->
        </div>
      </div>
    </div>
    <div
      :class="topNavData.type === 'C2' ? 'contentC2 fix' : 'contentF2 fix'"
      v-if="topNavData.showMenu"
    >
      <div style="background: #f8f8f8" class="top-1" @click="show()">
        <div>
          <span class="font27">{{ $t(topNavData.title) }}</span>
          <img
            v-if="topNavData.isShow"
            src="@/assets/image/accessorires2/arrow.png"
            alt=""
          />
          <img
            v-if="!topNavData.isShow"
            src="@/assets/image/accessorires2/arrow2.png"
            alt=""
          />
          <br style="clear: both" />
        </div>
        <!--<button>购买</button>-->
      </div>
      <div v-show="topNavData.isShow">
        <div class="top-2">
          <a @click="Click(0)" style="width: 100%" class="item1">
            <span :style="{ color: topNavData.num == 0 ? '#333' : '#ccc' }">{{
              $t("details.v4")
            }}</span>
            <div class="line"></div>
          </a>
          <a @click="Click(1)" style="width: 100%" class="item1">
            <span :style="{ color: topNavData.num != 0 ? '#333' : '#ccc' }">{{
              $t("details.v5")
            }}</span>
            <div class="line"></div>
          </a>
          <a @click="Click(9)" v-if="pdfMenu" style="width: 100%" class="item1">
            <span :style="{ color: topNavData.num != 0 ? '#333' : '#333' }">{{
              $t("C2.v14a")
            }}</span>
            <div class="line"></div>
          </a>
          <!--<div class="item1">
            <span>支持</span>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "topNav",
  props: ["topNavData"],
  data() {
    return {
      multiLanguage: false,
      pdfMenu: true,
      type:'',
    };
  },
  mounted() {
    this.multiLanguage = !["zh-hk", "zh-cn"].includes(
      localStorage.getItem("lans")
    );
    this.type = this.topNavData.type;
    let pdfMenu = true;
    if (this.type == 'F2') {
      if (this.$store.state.lang != 'zh-cn') {
        pdfMenu = false;
      }
    }
    this.pdfMenu = pdfMenu;
    console.log('type:', this.type, this.pdfMenu)
  },
  methods: {
    Click(num) {
      this.topNavData.num = num;

      if (num == 9) {
        let name = '';
        if (this.type == 'C2') {
          name = 'EC2.pdf';
          if (this.$store.state.lang != 'zh-cn' && this.$store.state.lang != 'zh-hk') {
            name = 'EC2G.pdf';
          }
        }
        if (this.type == 'P1') {
          name = 'P1.pdf';
          if (this.$store.state.lang != 'zh-cn' && this.$store.state.lang != 'zh-hk') {
            name = 'P1G.pdf';
          }
        }
        if (this.type == 'F2') {
          name = 'EF2.pdf';
        }

        const routeUrl = this.$router.resolve({
          path: "/pdf",
          query: { url: `https://image.iriding.cc/manual/${name}` },
        });

        window.open(routeUrl.href, "_blank");
        return;
      }

      if (num === 0) {
        document.getElementById("brief").scrollIntoView();
      } else if (num === 3) {
        document.querySelector("#de").style.height = "80px";
        document.getElementById("de").scrollIntoView();
      } else if (num === 1) {
        document.getElementById("de").scrollIntoView();
      }
    },
    show() {
      this.topNavData.isShow = !this.topNavData.isShow;
    },
  },
};
</script>

<style lang="less">
.contentC2 {
  width: 100%;
  position: relative;

  > img {
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
  }
  .title1 {
    display: inherit;
    position: absolute;
    top: 80px;
    width: 100%;
    text-align: center;
    font-size: 21px;
  }
  .title2 {
    display: inherit;
    position: absolute;
    top: 115px;
    width: 100%;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
  }

  .top-1 {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: initial;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    top: 0;
    > div {
      width: 100%;
      margin-left: 39px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        display: inline-block;
        font-family: MI LANTING;
        font-size: 13.5px;
        font-weight: 400;
        color: #3a3a3a;
      }

      img {
        width: 14px;
        height: 8px;
        margin-right: 30px;
      }

      /*button {
          width: 35px;
          color: #fff;
          font-size: 9px;
          height: 20px;
          background: #FF4D00;
          border: 0px solid #030000;
          opacity: 0.9;
          border-radius: 6px;
        }*/
    }
  }

  > div {
    width: 100%;
    height: 501px;
    /*border: 1px solid pink;*/
    display: flex;
    flex-direction: column;
    z-index: 1;
    top: 50px;
    position: absolute;

    .top-2 {
      width: 100%;
      background: #fff;

      .item1 {
        margin: 0 auto;
        position: relative;
        display: inline-block;
        span {
          line-height: 52.5px;
          display: inline-block;
          margin-left: 39px;
          font-size: 12px;
          font-family: MI LANTING;
          font-weight: 400;
          color: #3a3a3a;
        }

        .line {
          width: 306px;
          height: 0.5px;
          background: #3a3a3a;
          border: 0px solid #030000;
          opacity: 0.03;
          margin: 0 auto;
        }
      }
    }
  }
}
.fix {
  opacity: 0.9;
  position: fixed;
  top: 0px;
  z-index: 90;
}
.contentF2 {
  width: 100%;
  position: relative;

  > img {
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
  }
  .title1 {
    display: inherit;
    position: absolute;
    top: 80px;
    width: 100%;
    text-align: center;
    font-size: 21px;
  }
  .title2 {
    display: inherit;
    position: absolute;
    top: 115px;
    width: 100%;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
  }

  .top-1 {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: initial;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    top: 0;
    > div {
      width: 100%;
      margin-left: 39px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        display: inline-block;
        font-family: MI LANTING;
        font-size: 13.5px;
        font-weight: 400;
        color: #3a3a3a;
      }

      img {
        width: 14px;
        height: 8px;
        margin-right: 30px;
      }

      /*button {
            width: 35px;
            color: #fff;
            font-size: 9px;
            height: 20px;
            background: #FF4D00;
            border: 0px solid #030000;
            opacity: 0.9;
            border-radius: 6px;
          }*/
    }
  }

  > div {
    width: 100%;
    height: 501px;
    /*border: 1px solid pink;*/
    display: flex;
    flex-direction: column;
    z-index: 1;
    top: 50px;
    position: absolute;

    .top-2 {
      width: 100%;
      background: #fff;

      .item1 {
        margin: 0 auto;
        position: relative;
        display: inline-block;
        span {
          line-height: 52.5px;
          display: inline-block;
          margin-left: 39px;
          font-size: 12px;
          font-family: MI LANTING;
          font-weight: 400;
          color: #3a3a3a;
        }

        .line {
          width: 306px;
          height: 0.5px;
          background: #3a3a3a;
          border: 0px solid #030000;
          opacity: 0.03;
          margin: 0 auto;
        }
      }
    }
  }
}
.fix {
  opacity: 0.9;
  position: fixed;
  top: 0px;
  z-index: 90;
}
</style>