<template>
  <div class="footer">
    <div class="title font47 border p06 fontBold width100">
      {{ $t(footerData.title) }}
    </div>
    <div
      :class="index === 4 ? 'bottom69' : ''"
      class="mainItem"
      v-for="(item, index) in footerData.footer"
      :key="'footer' + index"
    >
      <div :class="footerData.type === 'F2' ? 'itemF2' : 'itemC2'">
        <div>
          <img
            style="margin-bottom: 0.5rem"
            :src="item.img1"
            :class="'img1' + index"
            :style="
              !Multi ? (index === 2 ? 'width:1.77rem;height:1.36rem' : '') : ''
            "
            alt=""
          />
        </div>
        <div v-if="item.text2">
          <img
            style="margin-bottom: 0.5rem"
            :src="item.img2"
            :class="'img2' + index"
            :style="
              !Multi
                ? index === 2
                  ? 'width:1.97rem;height:0.75rem'
                  : index === 1
                  ? 'width:1.17rem;height:1.59rem'
                  : ''
                : ''
            "
            alt=""
          />
        </div>
      </div>
      <div class="item2">
        <div
          :style="
            item.text3 && item.text3 !== '' ? 'flex-direction: column;' : ''
          "
        >
          <span class="textaC color3A fw400" v-html="$t(item.text1)"></span>
          <span
            style="text-align: center"
            v-if="item.text3 && item.text3 !== ''"
            >{{ $t(item.text3) }}</span
          >
        </div>
        <div v-if="item.text2">
          <span class="textaC color3A fw400">{{ $t(item.text2) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer",
  props: ["footerData"],
  components: {},
  data() {
    return {
      Multi: false,
    };
  },
  mounted() {
    if (!["zh-cn", "zh-hk"].includes(localStorage.getItem("lans"))) {
      this.Multi = true;
    }
  },
};
</script>

<style lang="less">
.footer {
  padding-bottom: 0.69rem;
  .title {
    height: 0.79rem;
    width: 6.25rem;
    margin: 0 auto;
    line-height: 0.79rem;
    margin-top: 0.8rem;
    font-size: 0.25rem;
  }
  .bottom69 {
    margin-bottom: 0.69rem;
  }
  .mainItem {
    margin-left: 0.6rem;
    margin-right: 0.6rem;
    margin-top: 0.77rem;
    .itemC2 {
      display: flex;
      > div {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        .img10 {
          width: 2.14rem;
          height: 1.53rem;
        }
        .img20 {
          width: 1.17rem;
          height: 1.6rem;
        }
        .img11 {
          width: 1.22rem;
          height: 1.05rem;
        }
        .img21 {
          width: 1.17rem;
          height: 1.59rem;
        }
        .img22 {
          width: 1.97rem;
          height: 0.75rem;
        }
        .img13 {
          width: 1.39rem;
          height: 0.84rem;
        }
        .img23 {
          width: 1.17rem;
          height: 1.59rem;
        }
        .img14 {
          width: 0.38rem;
          height: 0.56rem;
        }
      }
    }
    .itemF2 {
      display: flex;
      > div {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        .img10 {
          width: 1.99rem;
          height: 1.52rem;
        }
        .img20 {
          width: 1.15rem;
          height: 1.57rem;
        }
        .img11 {
          width: 1.22rem;
          height: 1.05rem;
        }
        .img21 {
          width: 1.66rem;
          height: 1.13rem;
        }
        .img12 {
          width: 1.75rem;
          height: 1.34rem;
        }
        .img22 {
          width: 1.97rem;
          height: 0.75rem;
        }
      }
    }
    .item2 {
      display: flex;
      > div {
        width: 50%;
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>